import React, { useEffect, useState } from "react";
import LanguageSelector from "./LanguageSelector";
function Language() {
  const [currentLanguage, setCurrentLanguage] = useState("pt");

  const handleLanguageChange = (selectedLanguage) => {
    setCurrentLanguage(selectedLanguage);
    // Lógica adicional para alterar o idioma em todo o aplicativo
  };
  const languages = [
    { code: "en", name: "English" },
    { code: "pt", name: "Portugês" },
    // Adicione mais idiomas conforme necessário
  ];
  useEffect(() => {
    // Obtém o idioma do navegador do usuário
    const browserLanguage = navigator.language || navigator.userLanguage;

    // Verifica se o idioma do navegador está entre os idiomas suportados
    const supportedLanguage = languages.find(
      (language) => language.code === browserLanguage,
    );

    // Define o idioma atual com base no idioma do navegador ou usa o inglês como padrão
    setCurrentLanguage(supportedLanguage ? supportedLanguage.code : "en");
    // console.log(currentLanguage);
  }, []);
  return (
    <div>
      <LanguageSelector
        languages={languages}
        currentLanguage={currentLanguage}
        onLanguageChange={handleLanguageChange}
      />
    </div>
  );
}

export default Language;

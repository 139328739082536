import React, { useContext } from "react";
import { LanguageContext } from "./LanguageContext";
import pt from "./images/flags/pt.png";
import en from "./images/flags/en.png";

const LanguageSelector = () => {
  const { currentLanguage, setLanguage } = useContext(LanguageContext);

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === "pt" ? "en" : "pt";
    setLanguage(newLanguage);
  };

  return (
    <div className="flex justify-center p-5">
      <img
        src={currentLanguage === "pt" ? pt : en}
        alt={currentLanguage === "pt" ? "Português" : "English"}
        onClick={handleLanguageChange}
        style={{
          cursor: "pointer",
          marginRight: "5px",
          height: "auto",
          width: "24px",
        }}
        className="h-8 w-8"
      />
    </div>
  );
};

export default LanguageSelector;

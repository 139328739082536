import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import axios from "axios";
import "./Portfolio.css";

import SkeletonLoader from "./SkeletonLoader ";
// import { Link } from "react-scroll";
const Portfolio = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const serverImageDirectory = "../../Projects/images/";

  const handleProjectClick = (slug) => {
    const projectURL = `./${slug}`;
    window.open(projectURL, "_blank");
  };
  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/projects/portfolio",
    );
    setData(response.data);
    setIsLoading(false);
  };

  const [visibleCards, setVisibleCards] = useState(8); // Number of initially visible cards

  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 4); // Increase visible cards by 4 on each load
  };
  function slugify(text) {
    return text
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }
  return (
    <div className="portfolio" id="portfolio">
      <div class="bg-gray-200 dark:bg-gray-900 py-10 px-12">
        <div className="wrapper py-10">
          <div class="grid grid-flow-row gap-8 text-neutral-600 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {isLoading ? (
              <div>
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                {/* Renderizar múltiplos SkeletonLoaders conforme necessário */}
              </div>
            ) : (
              data.slice(0, visibleCards).map((card, index) => (
                <div
                  key={index}
                  className="card-shadow figure my-8 rounded shadow-lg shadow-gray-200 dark:shadow-gray-900 bg-white dark:bg-gray-800 duration-300 hover:-translate-y-1"
                  onClick={() => handleProjectClick(slugify(card.Name))}
                >
                  <figure>
                    <img
                      loading="lazy"
                      src={`${serverImageDirectory}/${card.idProject}/${card.imagepath}`}
                      alt={card.Name}
                      class="rounded-t h-72 w-full object-cover"
                    />

                    <figcaption class="p-4">
                      <p
                        class="text-lg mb-4 font-bold leading-relaxed text-gray-800 dark:text-gray-300"
                        x-text="post.title"
                      >
                        {card.Name}
                      </p>

                      <small
                        class="leading-5 text-gray-500 dark:text-gray-400"
                        x-text="post.description"
                      >
                        {currentLanguage === "pt"
                          ? card.Description_PT
                          : card.Description_EN}
                      </small>
                    </figcaption>
                  </figure>
                </div>
              ))
            )}{" "}
          </div>
        </div>
        {visibleCards < data.length && (
          <div className="flex justify-center mt-4">
            <button
              className="bg-gray-100 dark:bg-gray-800 dark:text-white  text-gray-800 py-5 px-10  rounded card-shadow duration-300 hover:-translate-y-1"
              onClick={loadMoreCards}
            >
              {currentLanguage === "pt" ? "Carregar Mais" : "Load More"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;

import ContentLoader from "react-content-loader";

const SkeletonLoader = () => {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={120}
      viewBox="0 0 400 120"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="10" y="10" rx="5" ry="5" width="80" height="100" />
      <rect x="110" y="10" rx="4" ry="4" width="280" height="16" />
      <rect x="110" y="40" rx="3" ry="3" width="220" height="14" />
      <rect x="110" y="70" rx="3" ry="3" width="170" height="14" />
    </ContentLoader>
  );
};

export default SkeletonLoader;

import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import "./About.css";
import axios from "axios";

import aboutus1 from "./images/about_us_1.jpg";
import aboutus2 from "./images/about_us_2.jpg";
import aboutus3 from "./images/about_us_3.png";
import aboutus4 from "./images/about_us_4.png";

const About = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/aboutus/1",
    );
    setData(response.data);
  };

  return (
    <div className="about" id="about">
      <div className="wrapper py-10">
        <div className="title">
          <h2>{currentLanguage === "pt" ? data.Title_PT : data.Title_EN}</h2>
        </div>
        <div className="subtitle">
          <h5>
            {currentLanguage === "pt"
              ? data.Description_PT
              : data.Description_EN}
          </h5>
        </div>
      </div>
      <div className="section bg-gray-200 dark:bg-gray-900">
        <div className="card">
          <img
            loading="lazy"
            src={aboutus1}
            alt={
              currentLanguage === "pt"
                ? "Projeto chave na mão"
                : "Turnkey project"
            }
          />
          <div className="card-bottom">
            <p>
              <span>
                {currentLanguage === "pt"
                  ? "Projeto chave na mão"
                  : "Turnkey project"}
              </span>
            </p>
            <p>
              {currentLanguage === "pt"
                ? "Projeto de arquitetura e design de interiores"
                : "Architecture and interior design project"}
            </p>
          </div>
        </div>
        <div className="card">
          <img
            loading="lazy"
            src={aboutus2}
            alt={currentLanguage === "pt" ? "Decoração" : "Decoration"}
          />
          <div className="card-bottom">
            <p>
              <span>
                {currentLanguage === "pt" ? "Decoração" : "Decoration"}
              </span>
            </p>
            <p>
              {currentLanguage === "pt"
                ? "A revitalização estética e funcional de espaços"
                : "The aesthetic and functional revitalization of spaces"}
            </p>
          </div>
        </div>
        <div className="card">
          <img
            loading="lazy"
            src={aboutus3}
            alt={currentLanguage === "pt" ? "Projeto 3D" : "3D Project"}
          />
          <div className="card-bottom">
            <p>
              <span>
                {currentLanguage === "pt" ? "Projeto 3D" : "3D Project"}
              </span>
            </p>
            <p>
              {currentLanguage === "pt"
                ? "A representação virtual do projeto dos seus sonhos"
                : "The virtual representation of your dream project"}
            </p>
          </div>
        </div>
        <div className="card">
          <img
            loading="lazy"
            src={aboutus4}
            alt={
              currentLanguage === "pt"
                ? "Arquitetura de interiores"
                : "Interior architecture"
            }
          />
          <div className="card-bottom">
            <p>
              <span>
                {currentLanguage === "pt"
                  ? "Arquitetura de interiores"
                  : "Interior architecture"}
              </span>
            </p>
            <p>
              {currentLanguage === "pt"
                ? "Idealizamos o seu projeto de forma prática para o dia-a-dia."
                : "We design your project in a practical way for day-to-day life."}
            </p>
          </div>
        </div>
      </div>

      <div className="subtitle">
        <div className="wrapper py-10">
          <p>
            {currentLanguage === "pt"
              ? "Design & aRquitectura, duas faces da mesma moeda, unidas pelo prazer de criar e inovar. Da elaboração do projeto à execução da obra, o cliente é o cerne do trabalho, para o qual se produz a estética que identifica cada solução, simples e marcante."
              : "Design & Architecture, two sides of the same coin, united by the pleasure of creating and innovating. From project development to construction, the client is at the heart of the work, for which the aesthetics that identify each solution are produced, simple and striking."}
          </p>
        </div>{" "}
      </div>
    </div>
  );
};

export default About;

import React, { useContext, useState, useRef } from "react";
import { LanguageContext } from "./LanguageContext";
import "./Budget.css";
import axios from "axios";
import contactus_1 from "./images/contactus_1.jpg";
import contactus_2 from "./images/contactus_2.jpg";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";

const Budget = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [RecaptchaMessage, setRecaptchaMessage] = useState("");
  const [token, setToken] = useState("");
  const recaptchaRef = useRef(null);

  function onChange(value) {
    // console.log("Captcha value:", value);
    setToken(value);
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();
    data.captcha = token;
    axios
      .post("https://designearquitectura.pt/api/recaptcha", data)
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setIsSubmitted(true);
          reset();
          setRecaptchaMessage("");
          handleFormReset();
          setTimeout(() => {
            setIsSubmitted(false);
          }, 2000);
        } else {
          currentLanguage === "pt"
            ? setRecaptchaMessage("campo de preenchimento obrigatório")
            : setRecaptchaMessage("Please fill out this field.");
        }
      });
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: "",
    message: "",
    captcha: "",
  });

  const handleFormReset = () => {
    setData({
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
      captcha: "",
    });
    reset();
  };

  const getTranslatedText = (key) => {
    const translations = {
      pt: {
        title: "Orçamentos",
        subtitle: "Envie-nos todas as suas dúvidas ou pedidos de orçamento",
        firstNamePlaceholder: "Nome",
        lastNamePlaceholder: "Apelido",
        emailPlaceholder: "E-mail",
        subjectPlaceholder: "Assunto",
        messagePlaceholder: "A sua mensagem",
        submitButtonText: "Enviar",
      },
      en: {
        title: "Budgets",
        subtitle: "Send us all your questions or budget requests",
        firstNamePlaceholder: "First name",
        lastNamePlaceholder: "Last name",
        emailPlaceholder: "E-mail",
        subjectPlaceholder: "Subject",
        messagePlaceholder: "Your message",
        submitButtonText: "Send",
      },
    };

    const translation = translations[currentLanguage] || translations.pt;

    return translation[key];
  };

  return (
    <div>
      <div className="budget" id="budgets">
        <div className="wrapper py-10">
          <div className="title">
            <h2>{getTranslatedText("title")}</h2>
          </div>
          <div className="subtitle">
            <h5>{getTranslatedText("subtitle")}</h5>
          </div>
          <div className="container">
            <div className="col-2">
              <div className="">
                <div className="">
                  <img
                    src={contactus_1}
                    alt="Orçamentos"
                    className="card-shadow left-16  top-10 relative"
                  />
                  <img
                    src={contactus_2}
                    alt="Orçamentos"
                    className="card-shadow   right-16 top-0 relative"
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              {isSubmitted ? (
                <div className="success-message">
                  <div class="relative flex w-96 flex-col rounded-xl bg-green-500 bg-clip-border text-gray-700 shadow-md card-shadow">
                    <div class="p-6">
                      <h5 class="mb-2 block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                        {currentLanguage === "pt" ? "Obrigado" : "Thanks"}
                      </h5>
                      <p class="block font-sans text-base font-light leading-relaxed text-inherit antialiased">
                        {currentLanguage === "pt"
                          ? "Contacto submetido com sucesso!"
                          : "Contact submitted successfully!"}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="w-full max-w-lg"
                >
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="firstName"
                      >
                        {currentLanguage === "pt" ? "Nome" : "First Name"}
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200"
                        id="firstName"
                        type="text"
                        placeholder={getTranslatedText("firstNamePlaceholder")}
                        {...register("firstName", {
                          required: true,
                          maxLength: 100,
                        })}
                        defaultValue={data.firstName}
                        onChange={(e) =>
                          setData({ ...data, firstName: e.target.value })
                        }
                      />
                      {errors.firstName && (
                        <p className="text-red-500 text-xs italic ml-4 ">
                          {currentLanguage === "pt"
                            ? "campo de preenchimento obrigatório"
                            : "Please fill out this field."}
                        </p>
                      )}
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="lastName"
                      >
                        {currentLanguage === "pt" ? "Apelido" : "Last Name"}
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200"
                        id="lastName"
                        type="text"
                        placeholder={getTranslatedText("lastNamePlaceholder")}
                        {...register("lastName", {
                          required: true,
                          maxLength: 100,
                        })}
                        defaultValue={data.lastName}
                        onChange={(e) =>
                          setData({ ...data, lastName: e.target.value })
                        }
                      />
                      {errors.lastName && (
                        <p className="text-red-500 text-xs italic ml-4">
                          {currentLanguage === "pt"
                            ? "campo de preenchimento obrigatório"
                            : "Please fill out this field."}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        E-mail
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                        id="email"
                        type="email"
                        placeholder={getTranslatedText("emailPlaceholder")}
                        {...register("email", {
                          required: true,
                          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                        })}
                        defaultValue={data.email}
                        onChange={(e) =>
                          setData({ ...data, email: e.target.value })
                        }
                      />
                      {errors.email && (
                        <p className="text-red-500 text-xs italic ml-4">
                          {currentLanguage === "pt"
                            ? "campo de preenchimento obrigatório"
                            : "Please fill out this field."}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="subject"
                      >
                        {currentLanguage === "pt" ? "Assunto" : "Subject"}
                      </label>
                      <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500"
                        id="subject"
                        type="text"
                        placeholder={getTranslatedText("subjectPlaceholder")}
                        {...register("subject", {
                          required: true,
                          maxLength: 100,
                        })}
                        defaultValue={data.subject}
                        onChange={(e) =>
                          setData({ ...data, subject: e.target.value })
                        }
                      />
                      {errors.subject && (
                        <p className="text-red-500 text-xs italic ml-4">
                          {currentLanguage === "pt"
                            ? "campo de preenchimento obrigatório"
                            : "Please fill out this field."}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap -mx-3 mb-2">
                    <div className="w-full px-3">
                      <label
                        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        htmlFor="message"
                      >
                        {currentLanguage === "pt" ? "Mensagem" : "Message"}
                      </label>
                      <textarea
                        className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-gray-200 focus:border-gray-500 h-48 resize-none"
                        id="message"
                        placeholder={getTranslatedText("messagePlaceholder")}
                        {...register("message", {
                          required: true,
                          maxLength: 500,
                        })}
                        defaultValue={data.message}
                        onChange={(e) =>
                          setData({ ...data, message: e.target.value })
                        }
                      ></textarea>
                      {errors.message && (
                        <p className="text-red-500 text-xs italic ml-4">
                          {currentLanguage === "pt"
                            ? "campo de preenchimento obrigatório"
                            : "Please fill out this field."}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <div className="mb-4 " ref={recaptchaRef}>
                      <ReCAPTCHA
                        sitekey="6Le95Z0mAAAAADLFSinM_5XKVmZEXnFiqfqgTLWp"
                        hl={currentLanguage === "pt" ? "pt-PT" : "en-GB"}
                        onChange={onChange}
                      />
                    </div>
                    <p className="text-red-500 text-xs italic ml-4">
                      {RecaptchaMessage}
                    </p>
                    <button
                      id="submit-btn"
                      className="shadow w-full   bg-slate-800 hover:text-yellow-300 focus:shadow-outline focus:outline-none text-white font-bold py-4 px-4 rounded"
                      type="submit"
                      value={getTranslatedText("submitButtonText")}
                    >
                      {getTranslatedText("submitButtonText")}
                    </button>
                    <div className="md:w-2/3"></div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budget;

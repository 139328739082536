import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProjectDetails.css";
import axios from "axios";
import { Helmet } from "react-helmet";

import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

function ProjectDetails() {
  const { slug } = useParams();
  const [serverImages, setServerImages] = useState([]);
  const serverImageDirectory = "../../Projects/images";
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProjects();
        fetchServerImages(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const getProjects = async () => {
    try {
      const response = await axios.get(
        "https://designearquitectura.pt/api/projects/slug/" + slug,
      );
      setData(response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const fetchServerImages = async (projectData) => {
    try {
      const response = await axios.get(
        "https://designearquitectura.pt/api/projects/images/" +
          projectData.idProject,
      );
      setServerImages(response.data.images);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{data.Name}</title>
        <meta name="description" content={data.Description_PT} />
        <link rel="canonical" href={"https://designearquitectura.pt/" + slug} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={"https://designearquitectura.pt/" + slug}
        />
        <meta property="og:title" content="DR | Design & aRquitectura" />
        <meta property="og:description" content={data.Description_PT} />

        <meta
          property="og:image"
          content={
            "https://designearquitectura.pt/Projects/images" +
            `/${data.idProject}/${serverImages[0]}`
          }
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={"https://designearquitectura.pt/" + slug}
        />
        <meta property="twitter:title" content="DR | Design & aRquitectura" />
        <meta property="twitter:description" content={data.Description_PT} />
        <meta
          property="twitter:image"
          content={
            "https://designearquitectura.pt/Projects/images" +
            `/${data.idProject}/${serverImages[0]}`
          }
        />
      </Helmet>
      <div className=" bgdrarq p-5 wrapper">
        <div className="flex items-center justify-center mx-auto py-8">
          <div>
            <h1 className="text-5xl text-white font-bold text-center mb-4">
              {data.Name}
            </h1>
          </div>
        </div>
        <div className="flex items-center justify-center flex-nowrap align-middle mx-auto py-8">
          <p className="text-2xl text-white text-center mb-4">
            {data.Description_PT}
          </p>
        </div>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="16px">
            {serverImages.map((image, index) => (
              <img
                key={index}
                src={`${serverImageDirectory}/${data.idProject}/${image}`}
                alt={`Imagem ${index + 1}`}
                style={{ width: "100%", display: "block" }}
                className="text-center rounded-lg card-shadow duration-300 hover:-translate-y-1"
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
}

export default ProjectDetails;

import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import axios from "axios";

import "./Motto.css";

const Motto = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getAboutUs();
  }, []);

  const getAboutUs = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/aboutus/1",
    );
    setData(response.data);
  };
  return (
    <div className="motto" id="moto">
      <div className="main-content">
        <div className="main-content-box">
          {" "}
          {currentLanguage === "pt" ? data.Motto_PT : data.Motto_EN}
        </div>
      </div>
    </div>
  );
};

export default Motto;

import React, { useEffect, useState } from "react";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Budget from "./components/Budget";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Instagram from "./components/Instagram";
import Motto from "./components/Motto";
import Testimonials from "./components/Testimonials";
import { LanguageProvider } from "./components/LanguageContext";
import ProjectDetails from "./components/ProjectDetails";
import { Navigate } from "react-router-dom";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <LanguageProvider>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route path="/:slug" element={<ProjectPage />} />
          </Routes>
        </LanguageProvider>
      </Router>
    </div>
  );
}

function HomePage() {
  return (
    <div>
      <Header />
      <Home />
      <Motto />
      <About />
      <Portfolio />
      <Testimonials />
      {/* <Instagram /> */}
      <Budget />
      <Footer />
    </div>
  );
}

function ProjectPage() {
  const location = useLocation();
  useEffect(() => {
    const rootElement = document.documentElement;
    rootElement.classList.add("project-details");
  }, []);

  return (
    <div>
      <ProjectDetails />
      {/* Outros componentes relevantes para a página de detalhes do projeto */}
    </div>
  );
}

export default App;

import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import "./Testimonials.css";
import Slider from "react-slick";
import axios from "axios";
const Testimonials = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    getTestimonial();
  }, []);

  const getTestimonial = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/testimonial/projects",
    );
    setData(response.data);
  };

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          infinite: true,
          autoplay: true,
        },
      },
    ],
  };

  return (
    <div className="testimonials" id="testimonials">
      <div className="title ">
        <h2>Testemunhos</h2>
      </div>
      <div>
        <Slider {...settings}>
          {data.map((testimonial, index) => (
            <div
              className="flex justify-center items-center w-full h-40"
              key={index}
            >
              <div className="flex flex-col items-center text-center">
                {testimonial.ProjectName}
              </div>
              <div className="flex flex-col items-center mt-10 italic text-center">
                <p>
                  {currentLanguage === "pt"
                    ? testimonial.Description_PT
                    : testimonial.Description_EN}
                </p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;

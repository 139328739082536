import React, { useContext, useState, useEffect } from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import { SocialIcon } from "react-social-icons";
import { LanguageContext } from "./LanguageContext";
import axios from "axios";

const Footer = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [dataEmail, setDataEmail] = useState([]);
  const [dataPhone, setDataPhone] = useState([]);
  const [dataSocialMedia, setSocialMedia] = useState([]);

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/contacts",
    );
    const updatedDataPhone = response.data.filter(
      (item) => item.ContactType !== "EMAIL" && item.IsActive === 1,
    );
    const updatedDataEmail = response.data.filter(
      (item) => item.ContactType !== "PHONE" && item.IsActive === 1,
    );
    setDataEmail(updatedDataEmail);
    setDataPhone(updatedDataPhone);
  };

  useEffect(() => {
    getSocialMedia();
  }, []);

  const getSocialMedia = async () => {
    try {
      const response = await axios.get(
        "https://designearquitectura.pt/api/socialmedia/",
      );
      const active = response.data.filter((item) => item.IsActive === 1);
      setSocialMedia(active);
    } catch (error) {}
  };

  return (
    <div className="footer bg-gray-200 py-8">
      <div className="container mx-auto">
        <ul className="flex flex-wrap justify-center">
          <li className="nav-item">
            <Link
              to="hero"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="nav-link"
            >
              {currentLanguage === "pt" ? "Home" : "Home"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={0}
              duration={500}
              className="nav-link"
            >
              {currentLanguage === "pt" ? "Sobre nós" : "About Us"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="portfolio"
              spy={true}
              smooth={true}
              offset={-10}
              duration={500}
              className="nav-link"
            >
              {currentLanguage === "pt" ? "Portfólio" : "Portfolio"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="testimonials"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="nav-link"
            >
              {currentLanguage === "pt" ? "Testemunhos" : "Testimonials"}
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="budgets"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="nav-link"
            >
              {currentLanguage === "pt" ? "Orçamentos" : "Budgets"}
            </Link>
          </li>
        </ul>
        <div className="footer-content grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <div className="footer-content-column">
            <h4> {currentLanguage === "pt" ? "Contactos" : "Contacts"}</h4>
            <span className="line"></span>
            {dataPhone.map((item, index) => (
              <div key={index} className="py-2">
                <a href={"tel:" + item.Contact.trim()}>{item.Contact}</a>
              </div>
            ))}
            <p className="text-sm">
              (
              {currentLanguage === "pt"
                ? "chamada para rede móvel nacional"
                : "call to national mobile network"}
              )
            </p>
          </div>
          <div className="footer-content-column">
            <h4>E-mail</h4>
            <span className="line"></span>
            {dataEmail.map((item, index) => (
              <div key={index} className="py-2">
                <a href={"mailto:" + item.Contact}>{item.Contact}</a>
              </div>
            ))}
          </div>
          <div className="footer-content-column">
            <h4> {currentLanguage === "pt" ? "Segue-nos" : "Follow us"}</h4>
            <span className="line"></span>
            <div className="flex justify-evenly align-middle flex-wrap">
              {dataSocialMedia.map((item, index) => (
                <div key={index} className="">
                  <SocialIcon
                    url={item.SocialMediaURL}
                    fgColor="gray"
                    bgColor="transparent"
                  ></SocialIcon>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom mt-8">
          <span className="line"></span>
          <p className="text-center">
            {currentLanguage === "pt"
              ? `©${new Date().getFullYear()} by D&R - Design e Arquitectura. Todos os direitos reservados.`
              : `©${new Date().getFullYear()} by D&R - Design e Arquitectura. All rights reserved.`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;

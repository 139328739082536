import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import axios from "axios";
import { Helmet } from "react-helmet";

import "./Home.css";

import { motion } from "framer-motion";

const Home = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    gethomepage();
  }, []);

  const gethomepage = async () => {
    const response = await axios.get(
      "https://designearquitectura.pt/api/homepage/1",
    );
    setData(response.data);
  };

  return (
    <>
      <Helmet>
        <title>DR | Design & aRquitectura</title>
        <meta
          name="description"
          content="Na DR - Design e Arquitectura, acreditamos que todos os espaços, independentemente do formato ou tamanho, têm potencial para se tornar únicos e elegantes."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://designearquitectura.pt/" />
        <meta property="og:title" content="DR | Design & aRquitectura" />
        <meta
          property="og:description"
          content="Na DR - Design e Arquitectura, acreditamos que todos os espaços, independentemente do formato ou tamanho, têm potencial para se tornar únicos e elegantes."
        />
        <meta
          property="og:image"
          content="https://designearquitectura.pt/dr_logo.png"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://designearquitectura.pt/"
        />
        <meta property="twitter:title" content="DR | Design & aRquitectura" />
        <meta
          property="twitter:description"
          content="Na DR - Design e Arquitectura, acreditamos que todos os espaços, independentemente do formato ou tamanho, têm potencial para se tornar únicos e elegantes."
        />
        <meta
          property="twitter:image"
          content="https://designearquitectura.pt/dr_logo.png"
        />
      </Helmet>
      <div className="hero" id="hero">
        <div className="content">
          <p> {currentLanguage === "pt" ? data.Title_PT : data.Title_EN}</p>

          <motion.div
            className="company-text"
            initial={{ x: -500, opacity: 0, scale: 0.5 }}
            animate={{ x: 0, opacity: 1, scale: 1 }}
            transition={{ duration: 1.5 }}
          >
            <p>
              {currentLanguage === "pt" ? data.Subtitle_PT : data.Subtitle_EN}
            </p>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Home;

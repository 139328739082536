import React, { useContext, useState, useEffect } from "react";
import { LanguageContext } from "./LanguageContext";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-scroll";
import logo from "./images/dr_logo.svg";
import { motion } from "framer-motion";
import Language from "./Language";
import "./Header.css";

const Header = () => {
  const { currentLanguage } = useContext(LanguageContext);
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const closeMenu = () => setClick(false);

  return (
    <div className="header">
      <nav className="navbar">
        <motion.div
          initial={{ x: -500, opacity: 0, scale: 0.5 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          <Link
            to="hero"
            className="logo"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
          >
            <img
              src={logo}
              alt="logo"
              style={{
                width: "auto",
                height: "60px",
                filter: "invert(100%) contrast(100%)",
              }}
            />
          </Link>
        </motion.div>
        <motion.div
          initial={{ x: 500, opacity: 0, scale: 0.5 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          <div className="hamburger" onClick={handleClick}>
            {click ? (
              <FaTimes size={30} style={{ color: "#ffffff" }} />
            ) : (
              <FaBars size={30} style={{ color: "#ffffff" }} />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="hero"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={closeMenu}
              >
                {currentLanguage === "pt" ? "Home" : "Home"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={closeMenu}
              >
                {currentLanguage === "pt" ? "Sobre nós" : "About Us"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="portfolio"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={closeMenu}
              >
                {currentLanguage === "pt" ? "Portfólio" : "Portfolio"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="testimonials"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={closeMenu}
              >
                {currentLanguage === "pt" ? "Testemunhos" : "Testimonials"}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="budgets"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onClick={closeMenu}
              >
                {currentLanguage === "pt" ? "Orçamentos" : "Budgets"}
              </Link>
            </li>
            <Language />
          </ul>{" "}
        </motion.div>
      </nav>
    </div>
  );
};

export default Header;
